import React, { Component } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { all_routes as route } from "../../../Router/all_routes";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="main-wrapper">
          <div className="error-box">
            <div className="error-img">
              <ImageWithBasePath
                src="assets/img/authentication/error-500.png"
                className="img-fluid"
                alt="img"
              />
            </div>
            <h3 className="h2 mb-3">Oops, something went wrong</h3>
            <p>
              Server Error 500. We apologise and are fixing the problem Please
              try again at a later stage
            </p>
            <Link to={route.dashboard} className="btn btn-primary">
              Back to Dashboard
            </Link>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
