import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ChevronUp,
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import { useDispatch, useSelector } from "react-redux";
import { setToogleHeader } from "../../core/redux/action";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import useGetMealCategories from "../../core/hooks/useGetMealCategories";
import useDeleteMealCategory from "../../core/hooks/useDeleteMealCategory";
import AddMealCategoryList from "../../core/modals/meal/addmealcategorylist";
import EditMealCategoryList from "./../../core/modals/meal/editmealcategorylist";
import useAuth from "../../core/hooks/useAuth";
import useGetMealCategoryStatuses from "../../core/hooks/useGetMealCategoryStatuses";
import { MEAL_CATEGORIES_QUERY_KEY } from "../../core/queries/queryKeys";
import { useQueryClient } from "@tanstack/react-query";
import { normalizeErrorMessage } from "../../utils/errorUtils";

const MealCategoryList = () => {
  const [currentRecord, setCurrentRecord] = useState();

  const { auth } = useAuth();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);

  const { data: mealCategoriesData } = useGetMealCategories();

  const { data: mealCategoryStatusData } = useGetMealCategoryStatuses();

  const queryClient = useQueryClient();
  const modifiedCategories =
    mealCategoriesData?.data?.mealCategories?.map((category) => {
      const status = mealCategoryStatusData?.data?.mealCategoryStatuses?.find(
        (entry) => entry.id == category.statusId
      );
      return {
        ...category,
        status: status ? status.category : "Unknown",
      };
    }) || [];

  const deleteMeal = useDeleteMealCategory();

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
  const MySwal = withReactContent(Swal);

  const showDeleteConfirmationAlert = (handleClickConfirm) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",

      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleClickConfirm();
      } else {
        MySwal.close();
      }
    });
  };

  const handleDeleteConfirm = (id) => {
    deleteMeal.mutate(
      { id, userId: auth.userId },
      {
        onSuccess: () => {
          MySwal.fire({
            title: "Deleted!",
            text: "The meal category has been deleted.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            queryClient.invalidateQueries([MEAL_CATEGORIES_QUERY_KEY]);
          });
        },
        onError: (error) => {
          MySwal.fire({
            title: "Not Deleted!",
            text: normalizeErrorMessage(error),
            icon: "error",
            confirmButtonText: "OK",
          });
        },
      }
    );
  };
  const columns = [
    {
      title: "Category",
      dataIndex: "name",
      sorter: (a, b) => a.category.length - b.category.length,
    },
    {
      title: "Category Slug",
      dataIndex: "slug",
      sorter: (a, b) => a.categoryslug.length - b.categoryslug.length,
    },
    {
      title: "Created On",
      dataIndex: "date",
      sorter: (a, b) => a.createdon.length - b.createdon.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <span className="badge badge-linesuccess">
          <Link to="#"> {text}</Link>
        </span>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit-category"
              onClick={() => setCurrentRecord(record)}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link className="confirm-text p-2" to="#">
              <i
                data-feather="trash-2"
                className="feather-trash-2"
                onClick={() =>
                  showDeleteConfirmationAlert(() =>
                    handleDeleteConfirm(record.id)
                  )
                }
              ></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Category</h4>
                <h6>Manage your categories</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#add-category"
              >
                <PlusCircle className="me-2" />
                Add New Category
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table columns={columns} dataSource={modifiedCategories} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <AddMealCategoryList />
      <EditMealCategoryList currentRecord={currentRecord} />
    </div>
  );
};

export default MealCategoryList;
