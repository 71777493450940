export function normalizeErrorMessage(errorResponse) {
  const message = errorResponse?.response?.data?.error?.message;

  if (Array.isArray(message)) {
    return message.map((err) => err.message).join(", ");
  } else if (typeof message === "string") {
    return message;
  }
  return "An unknown error occurred.";
}
