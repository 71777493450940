import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import TextInput from "../../form-components/TextInput";
import useUpdateExpenseCategory from "./../../hooks/useUpdateExpenseCategory";
import Button from "../../form-components/Button";
import useAuth from "../../hooks/useAuth";
import { useQueryClient } from "@tanstack/react-query";
import { EXPENSE_CATEGORIES_QUERY_KEY } from "../../queries/queryKeys";
import { normalizeErrorMessage } from "../../../utils/errorUtils";

const schema = z.object({
  name: z.string().min(1, { message: "Name cannot be empty" }),
  description: z.string().min(1, { message: "Slug cannot be empty" }),
});

const EditExpenseCategory = ({ currentRecord: expenseCategory }) => {
  const updateExpenseCategory = useUpdateExpenseCategory();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const { auth } = useAuth();

  useEffect(() => {
    if (expenseCategory) {
      reset({
        name: expenseCategory.name,
        description: expenseCategory.description,
      });
    }
  }, [expenseCategory, reset]);

  const MySwal = withReactContent(Swal);

  const showUpdateConfirmation = (handleUpdateConfirmation) => {
    MySwal.fire({
      title: "Are you sure you want to edit this product category?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, update it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleUpdateConfirmation();
        queryClient.invalidateQueries([EXPENSE_CATEGORIES_QUERY_KEY]);
      } else {
        MySwal.close();
      }
    });
  };

  const handleUpdateConfirm = (data) => {
    data.userId = auth.userId;
    updateExpenseCategory.mutate(
      { id: expenseCategory.id, data },
      {
        onSuccess: () => {
          MySwal.fire({
            title: "Updated!",
            text: "The category has been updated.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            queryClient.invalidateQueries([EXPENSE_CATEGORIES_QUERY_KEY]);
          });
        },
        onError: (error) => {
          MySwal.fire({
            title: "Not Updated!",
            text: normalizeErrorMessage(error),
            icon: "error",
            confirmButtonText: "OK",
          });
        },
      }
    );
  };

  const onSubmit = async (data) => {
    try {
      showUpdateConfirmation(() => handleUpdateConfirm(data));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      {/* Edit Category */}
      <div className="modal fade" id="edit-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Category</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                      <TextInput
                        label="Name"
                        register={register("name")}
                        errors={errors.name}
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        label="Description"
                        register={register("description")}
                        errors={errors.description}
                      />
                    </div>
                    <div className="modal-footer-btn">
                      <Button
                        color="cancel"
                        label=" Cancel"
                        margin="me-2"
                        onClick={() => reset()}
                      />
                      <Button color="info" label="Save Changes" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Category */}
    </div>
  );
};

EditExpenseCategory.propTypes = {
  currentRecord: PropTypes.any.isRequired,
};

export default EditExpenseCategory;
