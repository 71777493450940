import React, { useState, useEffect } from "react";
import {
  MinusCircle,
  PlusCircle,
  Search,
} from "feather-icons-react/build/IconComponents";
import ImageWithBasePath from "../../img/imagewithbasebath";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod.js";
import SelectInput from "../../form-components/SelectInput";
import { Link } from "react-router-dom";
import useGetSuppliers from "./../../hooks/useGetSuppliers";
import useGetProducts from "./../../hooks/useGetProducts";
import { useForm } from "react-hook-form";
import Button from "../../form-components/Button";
import useAuth from "../../hooks/useAuth";
import { PropTypes } from "prop-types";
import useUpdatePurchasingOrder from "../../hooks/useUpdatePurchasingOrder";
import { useQueryClient } from "@tanstack/react-query";
import { PURCHASE_ORDER_QUERY_KEY } from "./../../queries/queryKeys";
import useGetPurchaseOrderStatuses from "../../hooks/useGetPurchaseOrderStatuses";
import useGetProductCategories from "./../../hooks/useGetProductCategories";
import { normalizeErrorMessage } from "../../../utils/errorUtils";

const schema = z.object({
  supplier: z.string().min(1, { message: "Supplier cannot be empty" }),
  product: z.string().min(1, { message: "Product cannot be empty" }),
  statusId: z.number().min(1, { message: "Status cannot be empty" }),
});

const EditPurchase = ({ currentRecord: purchase }) => {
  const { auth } = useAuth();

  const queryClient = useQueryClient();

  const { data: suppliersData, isSuccess: isSuppliersSuccess } =
    useGetSuppliers();
  const { data: productsData, isSuccess: isProductsSuccess } = useGetProducts();

  const updatePurchasingOrder = useUpdatePurchasingOrder();
  const { data: productCategories } = useGetProductCategories();

  const suppliersItems =
    isSuppliersSuccess && purchase
      ? suppliersData.data.suppliers
          .filter((supplier) => supplier.id === purchase.supplierId) // Filter suppliers based on purchase.supplierId
          .map((supplier) => ({
            value: supplier.id,
            label: supplier.name,
          }))
      : [];
  const { data: statusData = [] } = useGetPurchaseOrderStatuses([]);

  const statusOptions =
    statusData.data?.statuses.map((item) => ({
      value: item.id,
      label: item.name,
    })) || [];

  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    if (selectedSupplier && isProductsSuccess) {
      const products = productsData.data.products.filter(
        (product) => product.supplierId === selectedSupplier
      );
      setFilteredProducts(products);
    } else {
      setFilteredProducts([]);
    }
  }, [selectedSupplier, isProductsSuccess, productsData]);

  useEffect(() => {
    if (purchase && isProductsSuccess) {
      const products = purchase?.productList?.map((p) => {
        const productDetails = productsData?.data?.products?.find((product) => {
          return product.id === p.productId;
        });
        return {
          productId: p.productId,
          name: productDetails.name,
          imageUrl: productDetails.imageUrl,
          description: productDetails.description,
          category: productCategories?.data?.productCategories?.find(
            (category) => category.id === productDetails.categoryId
          )?.name,
          quantity: p.quantitySold,
          unitPrice: productDetails.unitPrice,
        };
      });
      setSelectedProducts(products);
      setSelectedSupplier(purchase.supplierId);
    }
  }, [purchase, isProductsSuccess, productsData]);

  const MySwal = withReactContent(Swal);

  const handleQuantityChange = (productId, increment) => {
    setSelectedProducts((prevSelectedProducts) =>
      prevSelectedProducts.map((item) =>
        item.productId === productId
          ? { ...item, quantity: Math.max(item.quantity + increment, 1) }
          : item
      )
    );
  };

  const handleProductSelect = (event) => {
    const productId = parseInt(event.target.value);
    const selectedProduct = filteredProducts.find(
      (product) => product.id === productId
    );

    if (selectedProduct) {
      setSelectedProducts((prevSelectedProducts) => {
        const existingProduct = prevSelectedProducts.find(
          (item) => item.productId === productId
        );

        if (existingProduct) {
          // If product already selected, increment the quantity
          return prevSelectedProducts.map((item) =>
            item.productId === productId
              ? { ...item, quantity: item.quantity + 1 }
              : item
          );
        } else {
          // If product not selected yet, add new entry
          return [
            ...prevSelectedProducts,
            {
              productId: selectedProduct.id,
              name: selectedProduct.name,
              imageUrl: selectedProduct.imageUrl,
              description: selectedProduct.description,
              category: selectedProduct.category,
              quantity: 1,
              unitPrice: selectedProduct.unitPrice,
            },
          ];
        }
      });
    }
  };

  const totalAmount = selectedProducts.reduce(
    (total, item) => total + item.quantity * item.unitPrice,
    0
  );

  const formattedSelectedItems = {
    totalAmount,
    products: selectedProducts.map((item) => ({
      productId: item.productId,
      quantity: item.quantity,
    })),
  };

  const handleRemoveProduct = (productId) => {
    setSelectedProducts((prevSelectedProducts) =>
      prevSelectedProducts.filter((item) => item.productId !== productId)
    );
  };

  const showCreateConfirmation = (handleCreateConfirmation) => {
    MySwal.fire({
      title: "Are you sure you want to create this purchasing order?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, order it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleCreateConfirmation();
      } else {
        MySwal.close();
      }
    });
  };

  const handleCreateConfirm = (data) => {
    data.userId = auth.userId;
    updatePurchasingOrder.mutate(
      { id: purchase.poId, data },
      {
        onSuccess: () => {
          MySwal.fire({
            title: "Updated!",
            text: "The purchase order has been updated.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            queryClient.invalidateQueries([PURCHASE_ORDER_QUERY_KEY]);
          });
        },
        onError: (error) => {
          MySwal.fire({
            title: "Not Updated!",
            text: normalizeErrorMessage(error),
            icon: "error",
            confirmButtonText: "OK",
          });
        },
      }
    );
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = (formData) => {
    const data = { ...formattedSelectedItems, statusId: formData.statusId };
    showCreateConfirmation(() => handleCreateConfirm(data));
  };

  return (
    <div>
      {/* Add Purchase */}
      <div className="modal fade" id="edit-units">
        <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Purchasing Order</h4>
                    <h6>Update Purchasing Order</h6>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>

                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-lg-12 col-12">
                        <div className="row seacrh-barcode-item">
                          <div className="col-sm-4 mb-2 seacrh-barcode-item-one">
                            <SelectInput
                              label="Supplier"
                              // options={[{ value: 1, label: "test" }]}
                              options={suppliersItems}
                              register={register("supplier")}
                              errors={errors.supplier}
                              onChange={(e) =>
                                setSelectedSupplier(parseInt(e.target.value))
                              }
                            />
                          </div>
                          <div className="col-sm-4 mb-2 seacrh-barcode-item-one">
                            <SelectInput
                              label="Product"
                              // options={[{ value: 1, label: "test" }]}
                              options={filteredProducts.map((product) => ({
                                value: product.id,
                                label: product.name,
                              }))}
                              register={register("product")}
                              errors={errors.product}
                              onChange={handleProductSelect}
                            />
                          </div>
                          <div className="col-sm-4 mb-2 seacrh-barcode-item-one">
                            <SelectInput
                              label="Status"
                              // options={[{ value: 1, label: "test" }]}
                              options={statusOptions}
                              register={register("statusId", {
                                setValueAs: (v) => parseInt(v, 10),
                              })}
                              errors={errors.statusId}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input-blocks search-form seacrh-barcode-item">
                          <div className="searchInput">
                            <label className="form-label">Product</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search Product by Codename"
                            />
                            <div className="resultBox"></div>
                            <div className="icon">
                              <Search className="fas fa-search" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="modal-body-table search-modal-header">
                        <div className="table-responsive">
                          <table className="table datanew">
                            <thead>
                              <tr>
                                <th>Product</th>
                                <th>Description</th>
                                <th>Category</th>
                                <th>Unit Price</th>
                                <th>Qty</th>
                                <th className="text-center no-sort">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedProducts.map((product, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className="productimgname">
                                      <Link
                                        to="#"
                                        className="product-img stock-img"
                                      >
                                        <ImageWithBasePath
                                          src={product.imageUrl}
                                          alt="product"
                                          type="R"
                                        />
                                      </Link>
                                      <p>{product.name}</p>
                                    </div>
                                  </td>
                                  <td>{product.description}</td>
                                  <td>{product.category}</td>
                                  <td>{product.unitPrice}</td>
                                  <td>
                                    <div className="product-quantity">
                                      <span
                                        className="quantity-btn"
                                        onClick={() =>
                                          handleQuantityChange(
                                            product.productId,
                                            -1
                                          )
                                        }
                                      >
                                        <MinusCircle />
                                      </span>
                                      <input
                                        type="text"
                                        className="quantity-input"
                                        value={product.quantity}
                                        readOnly
                                      />
                                      <span
                                        className="quantity-btn"
                                        onClick={() =>
                                          handleQuantityChange(
                                            product.productId,
                                            1
                                          )
                                        }
                                      >
                                        <PlusCircle />
                                      </span>
                                    </div>
                                  </td>
                                  <td className="action-table-data justify-content-center">
                                    <div className="edit-delete-action">
                                      <Link
                                        onClick={() =>
                                          handleRemoveProduct(product.productId)
                                        }
                                        className="confirm-text barcode-delete-icon"
                                        to="#"
                                      >
                                        <i
                                          data-feather="trash-2"
                                          className="feather-trash-2"
                                        />
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <Button
                        color="cancel"
                        label=" Cancel"
                        margin="me-2"
                        onClick={() => reset()}
                      />
                      <Button color="info" label="Submit" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* /Add Purchase */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPurchase;

EditPurchase.propTypes = {
  currentRecord: PropTypes.any.isRequired,
};
