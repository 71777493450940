import React, { useState } from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../Router/all_routes";
import Addunits from "../../core/modals/inventory/addunits";
import AddCategory from "../../core/modals/inventory/addcategory";
import AddBrand from "../../core/modals/addbrand";
import {
  ArrowLeft,
  ChevronDown,
  ChevronUp,
  LifeBuoy,
} from "feather-icons-react/build/IconComponents";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import TextInput from "../../core/form-components/TextInput";
import TextAreaInput from "../../core/form-components/TextAreaInput";
import SelectInput from "../../core/form-components/SelectInput";
import { useForm } from "react-hook-form";
import useAddMeal from "./../../core/hooks/useAddMeal";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod.js";
import FileUploadInput from "../../core/form-components/FileUploadInput";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Button from "../../core/form-components/Button";
import useAuth from "../../core/hooks/useAuth";
import useGetMealCategories from "./../../core/hooks/useGetMealCategories";
import { useQueryClient } from "@tanstack/react-query";
import { MEALS_QUERY_KEY } from "../../core/queries/queryKeys";
import { normalizeErrorMessage } from "./../../utils/errorUtils";
const schema = z.object({
  name: z.string().min(1, { message: "Name cannot be empty" }),
  description: z.string().min(1, { message: "Description cannot be empty" }),
  categoryId: z.number().min(1, { message: "Category cannot be empty" }),
  unitPrice: z.number().min(1, { message: "Price cannot be empty" }),
  images: z.any(),
});

const AddMeal = () => {
  const route = all_routes;

  const MySwal = withReactContent(Swal);

  const [previewURLs, setPreviewURLs] = useState([]);

  const addMeal = useAddMeal();

  const { auth } = useAuth();

  const { data: mealCategories } = useGetMealCategories();

  const categories =
    mealCategories?.data?.mealCategories?.map((status) => ({
      value: status.id,
      label: status.name,
    })) || [];
  const queryClient = useQueryClient();
  const showCreateConfirmation = (handleCreateConfirmation) => {
    MySwal.fire({
      title: "Are you sure you want to create this meal?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, order it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleCreateConfirmation();
      } else {
        MySwal.close();
      }
    });
  };

  const handleCreateConfirm = (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === "images") {
        formData.append(key, value[0]);
      } else {
        formData.append(key, value);
      }
    });
    formData.append("userId", auth.userId);
    addMeal.mutate(formData, {
      onSuccess: () => {
        MySwal.fire({
          title: "Created!",
          text: "The meal has been created.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          queryClient.invalidateQueries([MEALS_QUERY_KEY]);
        });
      },
      onError: (error) => {
        MySwal.fire({
          title: "Not Created!",
          text: normalizeErrorMessage(error),
          icon: "error",
          confirmButtonText: "OK",
        });
      },
    });
  };
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = (data) => {
    showCreateConfirmation(() => handleCreateConfirm(data));
  };

  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>New meal</h4>
                <h6>Create new meal</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <div className="page-btn">
                  <Link to={route.meallist} className="btn btn-secondary">
                    <ArrowLeft className="me-2" />
                    Back to meal
                  </Link>
                </div>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Collapse"
                    id="collapse-header"
                  >
                    <ChevronUp className="feather-chevron-up" />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
          {/* /add */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card">
              <div className="card-body add-product pb-0">
                <div
                  className="accordion-card-one accordion"
                  id="accordionExample2"
                >
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingTwo">
                      <div
                        className="accordion-button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-controls="collapseTwo"
                      >
                        <div className="text-editor add-list">
                          <div className="addproduct-icon list icon">
                            <h5>
                              <LifeBuoy className="add-info" />
                              <span>Details of Meal</span>
                            </h5>
                            <Link to="#">
                              <ChevronDown className="chevron-down-add" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample2"
                    >
                      <div className="accordion-body">
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                          >
                            <div className="row">
                              <div className="col-lg-6 col-sm-12 col-6">
                                <TextInput
                                  label="Name"
                                  register={register("name")}
                                  errors={errors.name}
                                />
                              </div>
                              <div className="col-lg-6 col-sm-12 col-6">
                                <TextAreaInput
                                  label="Description"
                                  register={register("description")}
                                  errors={errors.description}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 col-sm-12 col-6">
                                <div className="input-blocks add-product">
                                  <SelectInput
                                    label="Category"
                                    options={categories}
                                    register={register("categoryId", {
                                      setValueAs: (v) => parseInt(v, 10),
                                    })}
                                    errors={errors.categoryId}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-sm-12 col-6">
                                <TextInput
                                  label="Price"
                                  register={register("unitPrice", {
                                    setValueAs: (v) => parseInt(v, 10),
                                  })}
                                  errors={errors.unitPrice}
                                />
                              </div>
                            </div>
                            <div
                              className="accordion-card-one accordion"
                              id="accordionExample3"
                            >
                              <div className="accordion-item">
                                <div
                                  className="accordion-header"
                                  id="headingThree"
                                >
                                  <div
                                    className="accordion-button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-controls="collapseThree"
                                  >
                                    <div className="addproduct-icon list">
                                      <h5>
                                        <i
                                          data-feather="image"
                                          className="add-info"
                                        />
                                        <span>Images</span>
                                      </h5>
                                      <Link to="#">
                                        <ChevronDown className="chevron-down-add" />
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="collapseThree"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="headingThree"
                                  data-bs-parent="#accordionExample3"
                                >
                                  <div className="accordion-body">
                                    <div className="text-editor add-list add">
                                      <div className="col-lg-12">
                                        <FileUploadInput
                                          register={register}
                                          previewURLs={previewURLs}
                                          setPreviewURLs={setPreviewURLs}
                                          errors={errors.images}
                                          maxFiles={1}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="btn-addproduct mb-4">
                <Button
                  color="cancel"
                  label=" Cancel"
                  margin="me-2"
                  onClick={() => reset()}
                />
                <Button color="success" label="Save Meal" />
              </div>
            </div>
          </form>
        </div>
        <Addunits />
        <AddCategory />
        <AddBrand />
      </div>
    </>
  );
};

export default AddMeal;
