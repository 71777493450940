import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import useGetPendingMealOrders from "../../core/hooks/useGetPendingMealOrders";
import useUpdateMealStatus from "../../core/hooks/useUpdateMealStatus";
import useAuth from "../../core/hooks/useAuth";
import { useQueryClient } from "@tanstack/react-query";
import { normalizeErrorMessage } from "../../utils/errorUtils";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const KitchenDisplay = () => {
  const queryClient = useQueryClient();
  const { data: pendingMeals, isSuccess } = useGetPendingMealOrders();
  const updateMealStatus = useUpdateMealStatus();

  const { auth } = useAuth();
  const MySwal = withReactContent(Swal);
  const handleButtonClick = async (item) => {
    const newStatus = item.status === "PENDING" ? "PREPARING" : "READY";
    const statusId = newStatus === "PREPARING" ? 2 : 3;
    updateMealStatus.mutateAsync(
      {
        id: item.kitchenOrderId,
        statusId: statusId,
        userId: auth.userId,
      },
      {
        onSuccess: () => {
          if (statusId === 3) {
            queryClient.invalidateQueries(["pending-meals"]);
          }
        },
        onError: (error) => {
          MySwal.fire({
            title: "Not Updated!",
            text: normalizeErrorMessage(error),
            icon: "error",
            confirmButtonText: "OK",
          });
        },
      }
    );
    item.status = newStatus;
  };

  const getColorClass = (status) => {
    switch (status) {
      case "PENDING":
        return "primary";
      case "PREPARING":
        return "warning";
      case "READY":
        return "success";
      default:
        return "secondary";
    }
  };

  const getButtonLabel = (status) => {
    switch (status) {
      case "PENDING":
        return "Prepare";
      case "PREPARING":
        return "Ready";
      case "READY":
        return "Completed";
      default:
        return "Unknown";
    }
  };

  return (
    <div className="page-wrapper" style={{ margin: 0 }}>
      <div className="content">
        <div className="row">
          {isSuccess &&
            pendingMeals?.data?.orders?.map((item, key) => (
              <div className="col-sm-4 col-md-4 col-xl-3 d-flex" key={key}>
                <div className="card flex-fill default-cover w-100 mb-4">
                  <div
                    className={`card-header d-flex justify-content-between bg-${getColorClass(
                      item.status
                    )} align-items-center`}
                  >
                    <h4 className="card-title mb-0">Order #{item.orderId}</h4>
                    <div className="dropdown">
                      <span className="ps-100 d-flex align-items-center">
                        <FontAwesomeIcon
                          icon={faBullhorn}
                          data-bs-toggle="tooltip"
                          title="fa fa-shopping-basket"
                          color="white"
                          size="400"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-borderless best-seller">
                        <tbody>
                          {item?.meals?.map((meal, key) => (
                            <tr key={key}>
                              <td>{`x${meal?.quantity}`}</td>
                              <td>{meal?.name}</td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan="2">
                              <div
                                className="d-grid mb-4"
                                style={{ width: "100%;" }}
                              >
                                <button
                                  className={`btn btn-outline-${getColorClass(
                                    item?.status
                                  )}`}
                                  type="button"
                                  style={{ width: "100%;" }}
                                  onClick={() => handleButtonClick(item)}
                                >
                                  {getButtonLabel(item?.status)}
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default KitchenDisplay;
