import React from "react";

import * as Icon from "react-feather";
import { arePermissionsAllowed } from "../../Router/permissionChecker";
import useAuth from "../hooks/useAuth";

export const SidebarData = () => {
  const { auth } = useAuth();
  const userPermissions = auth.permissions;
  return [
    {
      label: "Main",
      showSubRoute: false,
      submenuHdr: "Dashboard",
      submenuItems: [
        {
          label: "Dashboard",
          link: "/admin-dashboard",
          icon: <Icon.Grid />,
          submenu: false,
          showSubRoute: false,
          isVisible: true,
        },
      ],
    },
    {
      label: "Stock",
      submenuOpen: true,
      showSubRoute: false,
      submenuHdr: "Stock",
      isVisible: arePermissionsAllowed(
        {
          PRODUCT: ["READ", "CREATE", "UPDATE", "DELETE"],
        },
        userPermissions
      ),
      submenuItems: [
        {
          label: "Products",
          link: "/product-list",
          icon: <Icon.Box />,
          showSubRoute: false,
          submenu: false,
          isVisible: arePermissionsAllowed(
            {
              PRODUCT: ["READ", "CREATE", "UPDATE", "DELETE"],
            },
            userPermissions
          ),
        },
        {
          label: "Create Product",
          link: "/add-product",
          icon: <Icon.PlusSquare />,
          showSubRoute: false,
          submenu: false,
          isVisible: arePermissionsAllowed(
            {
              PRODUCT: ["CREATE", "READ", "UPDATE", "DELETE"],
            },
            userPermissions
          ),
        },
        {
          label: "Category",
          link: "/category-list",
          icon: <Icon.Codepen />,
          showSubRoute: false,
          submenu: false,
          isVisible: arePermissionsAllowed(
            {
              PRODUCT_CATEGORY: ["CREATE", "READ", "UPDATE", "DELETE"],
            },
            userPermissions
          ),
        },
      ],
    },
    {
      label: "Meals",
      submenuOpen: true,
      showSubRoute: false,
      submenuHdr: "Meals",
      isVisible: arePermissionsAllowed(
        {
          MEAL: ["READ", "CREATE", "UPDATE", "DELETE"],
        },
        userPermissions
      ),
      submenuItems: [
        {
          label: "Meal",
          link: "/meal-list",
          icon: <Icon.Box />,
          showSubRoute: false,
          submenu: false,
          isVisible: arePermissionsAllowed(
            {
              MEAL: ["READ", "CREATE", "UPDATE", "DELETE"],
            },
            userPermissions
          ),
        },
        {
          label: "Create Meal",
          link: "/add-meal",
          icon: <Icon.PlusSquare />,
          showSubRoute: false,
          submenu: false,
          isVisible: arePermissionsAllowed(
            {
              MEAL: ["CREATE", "READ", "UPDATE", "DELETE"],
            },
            userPermissions
          ),
        },
        {
          label: "Category",
          link: "/meal-category-list",
          icon: <Icon.Codepen />,
          showSubRoute: false,
          submenu: false,
          isVisible: arePermissionsAllowed(
            {
              MEAL_CATEGORY: ["CREATE", "READ", "UPDATE", "DELETE"],
            },
            userPermissions
          ),
        },
      ],
    },
    {
      label: "Sales",
      submenuOpen: true,
      submenuHdr: "Sales",
      submenu: false,
      showSubRoute: false,
      submenuItems: [
        {
          label: "POS",
          link: "/pos",
          icon: <Icon.HardDrive />,
          showSubRoute: false,
          submenu: false,
          isVisible: arePermissionsAllowed(
            {
              POS: ["CREATE", "READ", "UPDATE"],
            },
            userPermissions
          ),
        },
        {
          label: "KDS",
          link: "/kitchen-display",
          icon: <Icon.Monitor />,
          showSubRoute: false,
          submenu: false,
          isVisible: arePermissionsAllowed(
            {
              KDS: ["READ", "CREATE", "UPDATE", "DELETE"],
            },
            userPermissions
          ),
        },
        {
          label: "MOD",
          link: "/meal-order-display",
          icon: <Icon.Eye />,
          showSubRoute: false,
          submenu: false,
          isVisible: arePermissionsAllowed(
            {
              MOD: ["READ"],
            },
            userPermissions
          ),
        },
      ],
    },
    {
      label: "Purchases",
      submenuOpen: true,
      submenuHdr: "Purchases",
      showSubRoute: false,
      isVisible: arePermissionsAllowed(
        {
          PURCHASE: ["CREATE"],
        },
        userPermissions
      ),
      submenuItems: [
        {
          label: "Purchases",
          link: "/purchase-list",
          icon: <Icon.ShoppingBag />,
          showSubRoute: false,
          submenu: false,
          isVisible: arePermissionsAllowed(
            {
              PURCHASE: ["CREATE", "READ", "UPDATE", "DELETE"],
            },
            userPermissions
          ),
        },
      ],
    },

    {
      label: "Finance & Accounts",
      submenuOpen: true,
      showSubRoute: false,
      submenuHdr: "Finance & Accounts",
      isVisible: arePermissionsAllowed(
        {
          EXPENSE: ["CREATE", "READ", "UPDATE", "DELETE"],
          EXPENSE_CATEGORY: ["CREATE", "READ", "UPDATE", "DELETE"],
        },
        userPermissions
      ),
      submenuItems: [
        {
          label: "Expenses",
          submenu: true,
          showSubRoute: false,
          icon: <Icon.FileText />,
          submenuItems: [
            {
              label: "Expenses",
              link: "/expense-list",
              showSubRoute: false,
              isVisible: arePermissionsAllowed(
                {
                  EXPENSE: ["CREATE", "READ", "UPDATE", "DELETE"],
                },
                userPermissions
              ),
            },
            {
              label: "Expense Category",
              link: "/expense-category",
              showSubRoute: false,
              isVisible: arePermissionsAllowed(
                {
                  EXPENSE_CATEGORY: ["CREATE", "READ", "UPDATE", "DELETE"],
                },
                userPermissions
              ),
            },
          ],
        },
      ],
    },

    {
      label: "People",
      submenuOpen: true,
      showSubRoute: false,
      submenuHdr: "People",
      isVisible: arePermissionsAllowed(
        {
          SUPPLIER: ["CREATE", "READ", "UPDATE", "DELETE"],
        },
        userPermissions
      ),
      submenuItems: [
        {
          label: "Suppliers",
          link: "/suppliers",
          icon: <Icon.Users />,
          showSubRoute: false,
          submenu: false,
          isVisible: arePermissionsAllowed(
            {
              SUPPLIER: ["CREATE", "READ", "UPDATE", "DELETE"],
            },
            userPermissions
          ),
        },
      ],
    },
    {
      label: "Settings",
      submenu: true,
      showSubRoute: false,
      submenuHdr: "Settings",
      isVisible: true,
      submenuItems: [
        {
          label: "Change Password",
          link: "/change-password",
          icon: <Icon.Key />,
          showSubRoute: false,
          isVisible: true,
        },
        {
          label: "Logout",
          link: "/logout",
          icon: <Icon.LogOut />,
          showSubRoute: false,
          isVisible: true,
        },
      ],
    },
  ];
};
