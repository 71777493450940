import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ImageWithBasePath from "../../img/imagewithbasebath";
import { Check, DollarSign } from "feather-icons-react/build/IconComponents";
import useAddPayment from "../../hooks/useAddPayment";
import Button from "../../form-components/Button";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import useAuth from "../../hooks/useAuth";
import PaymentCompleted from "./paymentcompleted";
import Modal from "../Modal";
import { normalizeErrorMessage } from "../../../utils/errorUtils";

const SelectPaymentMethod = ({ invoiceId, hide }) => {
  const [paymentMethodId, setPaymentMethodId] = useState();
  const [receiptId, setReceiptId] = useState();
  const { mutateAsync: addPayment } = useAddPayment();

  const MySwal = withReactContent(Swal);

  const { auth } = useAuth();

  const [isPaymentCompletedModalOpen, setIsPaymentCompletedModalOpen] =
    useState(false);

  const paymentMethods = [
    {
      src: "assets/img/icons/cash-pay.svg",
      alt: "Payment Method",
      label: "Cash",
      id: 1,
    },
    {
      src: "assets/img/icons/credit-card.svg",
      alt: "Payment Method",
      label: "Debit Card",
      id: 2,
    },
    {
      src: "assets/img/icons/qr-scan.svg",
      alt: "Payment Method",
      label: "Momo",
      id: 3,
    },
    {
      src: "assets/img/icons/qr-scan.svg",
      alt: "Payment Method",
      label: "Transfer",
      id: 4,
    },
  ];

  const handlePaymentMethodClicked = (id) => {
    setPaymentMethodId(id);
  };

  const handlePayment = async () => {
    try {
      await addPayment(
        {
          invoiceId,
          paymentMethodId,
          userId: auth.userId,
        },
        {
          onSuccess: (data) => {
            setReceiptId(data?.data?.receiptId);
            setIsPaymentCompletedModalOpen(true);
          },
          onError: (error) => {
            MySwal.fire({
              title: "Payment Error!",
              text: normalizeErrorMessage(error),
              icon: "error",
              confirmButtonText: "OK",
            });
          },
        }
      );
    } catch (error) {
      MySwal.fire({
        title: "Payment Error",
        text: "Failed to process payment",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <button type="button" className="close p-0" onClick={hide}>
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body text-center">
        <div className="icon-head">
          <Link to="#">
            <DollarSign />
          </Link>
        </div>
        <div className="block-section payment-method">
          <h6>Pay</h6>
          <div className="pos-products">
            <div className="tabs_container">
              <div className="tab_content active" data-tab="all">
                <div className="row">
                  {paymentMethods.map((method) => (
                    <div
                      key={method.id}
                      className="col-sm-4 col-md-4 col-lg-4 col-xl-4"
                    >
                      <div
                        className={`product-info default-cover card ${
                          paymentMethodId === method.id ? "selected" : ""
                        }`}
                        onClick={() => handlePaymentMethodClicked(method.id)}
                      >
                        <Link to="#" className="img-bg">
                          <ImageWithBasePath
                            src={method.src}
                            alt={method.alt}
                          />
                          <span>
                            <Check className="feather-16" />
                          </span>
                        </Link>
                        <h6 className="product-name">
                          <Link to="#">{method.label}</Link>
                        </h6>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="d-grid gap-2 mb-4">
            <Button
              color="success"
              label="Pay"
              disabled={!paymentMethodId}
              onClick={handlePayment}
            />
          </div>
        </div>
      </div>
      <Modal modalId="payment-completed" show={isPaymentCompletedModalOpen}>
        <PaymentCompleted receiptId={receiptId} />
      </Modal>
    </>
  );
};

SelectPaymentMethod.propTypes = {
  invoiceId: PropTypes.number,
  hide: PropTypes.any,
};

export default SelectPaymentMethod;
