import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  RotateCw,
  ShoppingCart,
} from "feather-icons-react/build/IconComponents";
import { Check, Trash2 } from "react-feather";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import MinusCircle from "feather-icons-react/build/IconComponents/MinusCircle";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useGetMealCategories from "./../../core/hooks/useGetMealCategories";
import useGetProductCategories from "../../core/hooks/useGetProductCategories";
import useGetMeals from "./../../core/hooks/useGetMeals";
import useGetProducts from "./../../core/hooks/useGetProducts";
import SelectInput from "../../core/form-components/SelectInput";
import useGetActiveReservation from "../../core/hooks/useGetActiveReservation";
import TextInput from "./../../core/form-components/TextInput";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod.js";
import { useForm } from "react-hook-form";
import useAddPosOrder from "../../core/hooks/useAddPosOrder";
import useGetPosOrderNo from "../../core/hooks/useGetPosOrderNo";
import OrderConfirmation from "../../core/modals/sales/orderconfirmation";
import OrderCompleted from "../../core/modals/sales/ordercompleted";
import RecentOrders from "../../core/modals/sales/recentorders";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Button from "../../core/form-components/Button";
import useAuth from "../../core/hooks/useAuth";

import Modal from "../../core/modals/Modal";
import PrintOrderConfirmation from "../../core/modals/sales/printorderconfirmation";
import { normalizeErrorMessage } from "../../utils/errorUtils";

const schema = z.object({
  bookingIdentifier: z
    .number({ invalid_type_error: "Select a Booking Option" })
    .min(0, { message: "Booking Identifier is Invalid" }),
  customerName: z.string().optional(),
});

const Pos = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [cart, setCart] = useState([]);
  const [activeBookings, setActiveBookings] = useState([]);
  const [isEnterNameVisible, setIsEnterNameVisible] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const [bookingIdentifier, setBookingIdentifier] = useState();

  const [orderDetails, setOrderDetails] = useState({});
  const [isOrderCompletedModalOpen, setIsOrderCompletedModalOpen] =
    useState(false);

  const [isPrintOrderConfirmModelOpen, setIsPrintOrderConfirmModelOpen] =
    useState(false);
  const [isRecentOrdersModalOpen, setIsRecentOrdersModalOpen] = useState(false);

  const { data: mealCategoryData, isSuccess: isMealCategorySuccess } =
    useGetMealCategories();
  const { data: productCategoryData, isSuccess: isProductCategorySuccess } =
    useGetProductCategories();
  const { data: mealData, isSuccess: isMealDataSuccess } = useGetMeals();
  const { data: productData, isSuccess: isProductDataSuccess } =
    useGetProducts();
  const { data: activeReservationData, isSuccess: isActiveReservationData } =
    useGetActiveReservation();
  const { mutateAsync: addPosOrder } = useAddPosOrder();
  const { data: posOrderNoData, isSuccess: isPosOrderNoData } =
    useGetPosOrderNo();

  const MySwal = withReactContent(Swal);

  const refreshPage = () => {
    window.location.reload();
  };

  const { auth } = useAuth();

  const hideModal = () => {
    setIsOrderCompletedModalOpen(false);
  };

  const onSubmit = (data) => {
    const reservationId =
      data.bookingIdentifier === 0 ? undefined : data.bookingIdentifier;
    const customerTypeId = data.bookingIdentifier === 0 ? 2 : 1;
    const customerName = data.customerName;

    const meals = cart
      .filter((cartItem) => cartItem.type === "meal")
      .map((cartItem) => ({
        id: cartItem.id,
        quantity: cartItem.quantity,
      }));

    const products = cart
      .filter((cartItem) => cartItem.type === "product")
      .map((cartItem) => ({
        id: cartItem.id,
        quantity: cartItem.quantity,
      }));

    const order = {
      orderNo: isPosOrderNoData && posOrderNoData.data.orderNo,
      meals,
      products,
      totalAmount: grandTotal,
      customerTypeId,
      customerDetails: {
        customerName,
        reservationId,
      },
      userId: auth.userId,
    };

    OrderConfirmation(async () => {
      try {
        await addPosOrder(order, {
          onSuccess: (data) => {
            setOrderDetails({
              bookingIdentifier,
              invoiceId: data?.data.invoiceId,
            });
            setIsOrderCompletedModalOpen(true);
          },
          onError: (error) => {
            MySwal.fire({
              title: "No Order Placed!",
              text: normalizeErrorMessage(error),
              icon: "error",
              confirmButtonText: "OK",
            });
          },
        });
      } catch (error) {
        MySwal.fire({
          title: "No Order Placed!",
          text: "Failed to place order",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    });
  };

  const selectedBookingIdentifierOption = watch("bookingIdentifier");
  React.useEffect(() => {
    if (selectedBookingIdentifierOption === 0) {
      setIsEnterNameVisible(true);
    } else {
      setIsEnterNameVisible(false);
    }
  }, [selectedBookingIdentifierOption]);

  const handleAddToCart = (item) => {
    setCart((prevCart) => {
      const itemInCart = prevCart.find(
        (cartItem) => cartItem.id === item.id && cartItem.type === item.type
      );
      if (itemInCart) {
        return prevCart.map((cartItem) =>
          cartItem.id === item.id
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        );
      } else {
        return [...prevCart, { ...item, quantity: 1 }];
      }
    });
  };

  useEffect(() => {
    calculateTotal();
  }, []);

  useEffect(() => {
    calculateTotal();
  }, [cart]);

  const calculateTotal = () => {
    setGrandTotal(
      cart.reduce((total, item) => total + item.unitPrice * item.quantity, 0)
    );
  };

  const handleIncrement = (id) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === id ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const handleDecrement = (id) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === id && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  const handleRemoveFromCart = (id) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== id));
  };

  const fetchActiveBooking = async () => {
    try {
      const walkInBooking = [
        { value: "", label: "Select Option" },
        { value: 0, label: "Walk in Customer" },
      ];
      const activeBookings = activeReservationData
        ? activeReservationData?.data?.activeReservations?.map(
            (reservation) => ({
              label: `${reservation.lastName} ${reservation.middleName} ${reservation.firstName} - Room ${reservation.roomNumber}`,
              value: reservation.reservationId,
            })
          )
        : [];
      const allBookings = [...walkInBooking, ...activeBookings];
      setActiveBookings(allBookings);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const productCategoriesWithType = productCategoryData
        ? productCategoryData.data.productCategories.map((category) => ({
            ...category,
            type: "product",
          }))
        : [];
      const mealCategoriesWithType = mealCategoryData
        ? mealCategoryData.data.mealCategories.map((category) => ({
            ...category,
            type: "meal",
          }))
        : [];

      const combinedCategories = [
        ...productCategoriesWithType,
        ...mealCategoriesWithType,
      ];

      const allCategories = {
        id: "all",
        name: "All Categories",
        imageUrl: "assets/img/categories/category-01.png",
        itemCount: combinedCategories.length,
        type: "all",
      };
      setCategories([allCategories, ...combinedCategories]);

      const productWithType = productData
        ? productData.data.products.map((category) => ({
            ...category,
            type: "product",
          }))
        : [];
      const mealWithType = mealData
        ? mealData.data.meals.map((category) => ({
            ...category,
            type: "meal",
          }))
        : [];

      const combinedItems = [...productWithType, ...mealWithType];
      setItems(combinedItems);
      setFilteredItems(combinedItems);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  useEffect(() => {
    fetchCategories();
    fetchActiveBooking();
  }, [
    isMealCategorySuccess,
    isProductCategorySuccess,
    isMealDataSuccess,
    isProductDataSuccess,
    isActiveReservationData,
  ]);

  const handleCategoryClick = async (category) => {
    if (category.type === "all") {
      setFilteredItems(items);
    } else {
      const filtered = items.filter((item) => {
        return item.type === category.type && item.name === category.name;
      });
      setFilteredItems(filtered);
    }
  };

  const settings = {
    dots: false,
    autoplay: false,
    slidesToShow: 5,
    margin: 0,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="page-wrapper pos-pg-wrapper ms-0">
        <div className="content pos-design p-0">
          <div className="btn-row d-sm-flex align-items-center">
            <Link
              to="#"
              className="btn btn-secondary mb-xs-3"
              onClick={() => setIsRecentOrdersModalOpen(true)}
            >
              <span className="me-1 d-flex align-items-center">
                <ShoppingCart className="feather-16" />
              </span>
              View Orders
            </Link>
            <Link to="#" className="btn btn-info" onClick={refreshPage}>
              <span className="me-1 d-flex align-items-center">
                <RotateCw className="feather-16" />
              </span>
              Reset
            </Link>
          </div>
          <div className="row align-items-start pos-wrapper">
            <div className="col-md-12 col-lg-8">
              <div className="pos-categories tabs_wrapper">
                <h5>Categories</h5>
                <p>Select From Below Categories</p>
                <Slider
                  {...settings}
                  className="tabs owl-carousel pos-category"
                >
                  {categories &&
                    categories.map((category) => (
                      <div
                        key={category.id}
                        id={category.name}
                        className="pos-slick-item"
                        onClick={() => handleCategoryClick(category)}
                      >
                        <Link to="#">
                          <ImageWithBasePath
                            src={category.imageUrl}
                            alt="Categories"
                            type={`${category.type === "all" ? "L" : "R"}`}
                          />
                        </Link>
                        <h6>
                          <Link to="#">{category.name}</Link>
                        </h6>
                        <span>{category.itemCount} Items</span>
                      </div>
                    ))}
                </Slider>
                <div className="pos-products">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-3">Products</h5>
                  </div>
                  <div className="tabs_container">
                    <div className="tab_content active" data-tab="all">
                      <div className="row">
                        {filteredItems &&
                          filteredItems.map((item, key) => (
                            <div
                              key={key}
                              className="col-sm-2 col-md-6 col-lg-3 col-xl-3"
                            >
                              <div
                                className="product-info default-cover card"
                                onClick={() => handleAddToCart(item)}
                              >
                                <Link to="#" className="img-bg">
                                  <ImageWithBasePath
                                    src={item.imageUrl}
                                    alt="items"
                                    type="R"
                                  />
                                  <span>
                                    <Check className="feather-16" />
                                  </span>
                                </Link>
                                <h6 className="cat-name">
                                  <Link to="#">{item.category}</Link>
                                </h6>
                                <h6 className="product-name">
                                  <Link to="#">{item.name}</Link>
                                </h6>
                                <div className="d-flex align-items-center justify-content-between price">
                                  <span>
                                    {item.quantityOnHand &&
                                      `${item.quantityOnHand} Pcs`}
                                  </span>
                                  <p>{`GNF ${item.unitPrice}`}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 ps-0">
              <aside className="product-order-list">
                <div className="head d-flex align-items-center justify-content-between w-100">
                  <div className="">
                    <h5>Order List</h5>
                    <span>{`Order #: ${
                      isPosOrderNoData && posOrderNoData.data.orderNo
                    }`}</span>
                  </div>
                </div>
                <div className="product-added block-section">
                  <div className="head-text d-flex align-items-center justify-content-between">
                    <h6 className="d-flex align-items-center mb-0">
                      Product Added<span className="count">{cart.length}</span>
                    </h6>

                    <Link
                      onClick={() => setCart([])}
                      className="d-flex align-items-center text-danger"
                    >
                      <span className="me-1">
                        <i data-feather="x" className="feather-16" />
                      </span>
                      Clear all
                    </Link>
                  </div>
                  <div className="product-wrap">
                    {cart.map((item) => (
                      <div
                        key={item.id}
                        className="product-list d-flex align-items-center justify-content-between"
                      >
                        <div
                          className="d-flex align-items-center product-info"
                          data-bs-target="#products"
                        >
                          <Link to="#" className="img-bg">
                            <ImageWithBasePath
                              src={item.imageUrl}
                              alt={item.name}
                              type="R"
                            />
                          </Link>
                          <div className="info">
                            <span>{item.code}</span>
                            <h6>
                              <Link to="#">{item.name}</Link>
                            </h6>
                            <p>{`GNF ${item.unitPrice}`}</p>
                          </div>
                        </div>
                        <div className="qty-item text-center">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-minus">Minus</Tooltip>
                            }
                          >
                            <Link
                              to="#"
                              className="dec d-flex justify-content-center align-items-center"
                              onClick={() => handleDecrement(item.id)}
                            >
                              <MinusCircle className="feather-14" />
                            </Link>
                          </OverlayTrigger>

                          <input
                            type="text"
                            className="form-control text-center"
                            name="qty"
                            value={item.quantity}
                            readOnly
                          />

                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip-plus">Plus</Tooltip>}
                          >
                            <Link
                              to="#"
                              onClick={() => handleIncrement(item.id)}
                              className="inc d-flex justify-content-center align-items-center"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="plus"
                            >
                              <PlusCircle className="feather-14" />
                            </Link>
                          </OverlayTrigger>
                        </div>
                        <div className="d-flex align-items-center action">
                          <Link
                            onClick={() => handleRemoveFromCart(item.id)}
                            className="btn-icon delete-icon confirm-text"
                            to="#"
                          >
                            <Trash2 className="feather-14" />
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="d-grid btn-block ">
                  <Link className="btn btn-secondary" to="#">
                    {`Grand Total: GNF ${grandTotal}`}
                  </Link>
                  <div className="d-grid gap-2 mt-4">
                    <Button
                      color="info"
                      label="Print Order for Confirmation"
                      disabled={!cart.length}
                      onClick={() => setIsPrintOrderConfirmModelOpen(true)}
                    />
                  </div>
                </div>
                <div className="customer-info block-section">
                  <h6>Customer Information</h6>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-block d-flex align-items-center">
                      <div className="flex-grow-1">
                        <SelectInput
                          register={register("bookingIdentifier", {
                            setValueAs: (v) => parseInt(v, 10),
                          })}
                          options={activeBookings}
                          errors={errors.bookingIdentifier}
                          onChange={(e) => {
                            setBookingIdentifier(e.target.value);
                            setValue("bookingIdentifier", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {isEnterNameVisible && (
                      <div className="input-block d-flex align-items-center">
                        <div className="flex-grow-1">
                          <TextInput
                            register={register("customerName")}
                            label="Customer Name (Optional)"
                            errors={errors.customerName}
                          />
                        </div>
                      </div>
                    )}
                    <div className="d-grid gap-2 mb-4">
                      <Button
                        color="danger"
                        label="Place Order"
                        disabled={!cart.length}
                      />
                    </div>
                  </form>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
      <Modal modalId="order-completed" show={isOrderCompletedModalOpen}>
        <OrderCompleted {...orderDetails} hide={hideModal} />
      </Modal>
      <Modal modalId="print-invoice" show={isPrintOrderConfirmModelOpen}>
        <PrintOrderConfirmation
          cart={cart}
          grandTotal={grandTotal}
          hide={() => setIsPrintOrderConfirmModelOpen(false)}
        />
      </Modal>
      <Modal
        modalId="orders"
        modalSize="modal-md"
        role="document"
        modalType="pos-modal"
        show={isRecentOrdersModalOpen}
      >
        <RecentOrders hide={() => setIsRecentOrdersModalOpen(false)} />
      </Modal>
    </div>
  );
};

export default Pos;
