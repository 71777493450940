import React from "react";
import PropTypes from "prop-types";

export default function Alert({ theme, label }) {
  return (
    <div className="card-body">
      <div className={`alert alert-${theme}`} role="alert">
        {label}
      </div>
    </div>
  );
}
Alert.propTypes = {
  theme: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
};
